import React from "react";
import { graphql, withPrefix, Link } from "gatsby";
import Layout from "../layouts/index";

const Home = (props) => {
  return (
    <Layout bodyClass="page-home">
      <div className="intro pb-4">
        <div className="container">
          <h1>Sie haben Post!</h1>
          <p>
            Wir haben Ihnen eine E-Mail mit einem Link gesendet. Bitte klicken Sie
            auf den Link, um Ihre E-Mail Addresse zu bestätigen.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
